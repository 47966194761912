class HomeLandingsTitleLanguage {
  id;
  constructor(data) {
    this.id = data.id
    this.language = data.language
    this.title = data.title
  }
}
var DataConverter = {
  toFirestore: function() {
    return {
    }
  },
  fromFirestore: function(snapshot, options) {
    let data = snapshot.data(options);
    data.id = snapshot.id
    return new HomeLandingsTitleLanguage(data)
  }
}
export {HomeLandingsTitleLanguage, DataConverter} 