<template>
  <div>
    <portal to="title-page">
      <h1>Titulo del listado de las landings</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          mdi-pencil
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small @click.stop="dialogAddLang.show = true" color="primary" class="ml-auto"><v-icon>mdi-plus</v-icon> lenguaje</v-btn>
        <v-btn small class="ml-2" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!-- <v-text-field
                label="Idioma"
                :prepend-icon="mdiFlag"
                :value="language ? language.name : ''"
                dense
                readonly
              ></v-text-field> -->
              <v-select
                v-if="registerLanguages != null"
                v-model="language"
                :items="registerLanguages"
                item-text="language"
                return-object
                prepend-icon="mdi-flag"
                :rules="[v => !!v || 'El idioma es requerido']"
                label="Seleccione el idioma"
                required
                @change="changeLang"
              ></v-select>
            </v-col>
            <v-col v-if="configLanguage" cols="12" sm="12" md="12">
              <v-text-field
              v-model="configLanguage.title"
              :rules="nameRules"
              prepend-icon="mdi-text"
              :disabled="ref && ref.id === '35GgLDUefBqgKGSqiMhv'"
              required
              dense
              label="Titulo*"></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                <v-icon>mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogAddLang.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Seleccione</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormLang"
            lazy-validation
          >
            <v-select
              v-if="availableLanguages != null"
              v-model="newLang"
              :items="availableLanguages"
              item-text="name"
              return-object
              prepend-icon="mdi-flag"
              :rules="[v => !!v || 'El idioma es requerido']"
              label="Seleccione el idioma"
              required
            ></v-select>
            <p>Una vez seleccionado el lenguaje haga click en agregar y cuando se cierre esta ventana edite los datos.</p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogAddLang.show = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validFormLang"
            @click="addLang"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Language from "@/models/Language.js";
import {DataConverter} from "@/FirestoreDataConverters/HomeLandingsTitleLanguage.js";
import Configs from "@/models/Configs.js";
export default {
  name: 'AdminCMSHomeLandingsTitle',
  data() {
    return {
      configsModel: new Configs,
      languageModel:new Language,
      validForm:true,
      newLang:null,
      systemLanguages:null,
      unsubscribeLangs:null,
      registerLanguages:null,
      availableLanguages:null,
      configLanguage:{title: null},
      ref:null,
      nameRules: [
        v => !!v || 'El titulo es obligatorio',
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      snackbar:{
        show:false,
        color:'',
        message:''
      },
      dialogAddLang:{
        show:false
      },
      validFormLang:true,
      selectedLanguageId:'es',
    }
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  },
  methods : {
    validateForm() {
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        /*if (this.configLanguage.id == 'es') {
          this.ref.update({name:this.categoryExtraLanguage.name}).then(() => {
            this.ref.collection('homeLandingsTitleLanguages').doc(this.configLanguage.id).update({
              title:this.configLanguage.title,
              language:this.configLanguage.language
            })
            .then(() => {
              loading.hide();
              this.showSnackbarSuccess('La categoria se actualizo correctamente');
            })
            .catch(error => {
              loading.hide();
              console.log(error);
              this.showSnackbarError('Error al actualizar el lenguaje de la categoria');
            })
          })
          .catch( error=> {
            console.log(error);
            loading.hide();
            this.showSnackbarError('Error al actualizar la categoria');
          })
        } else {
          }*/
        this.ref.collection('homeLandingsTitleLanguages').doc(this.configLanguage.id).update({
          title:this.configLanguage.title,
          language:this.configLanguage.language
        })
        .then(() => {
          loading.hide();
          this.showSnackbarSuccess('El lenguaje se actualizo correctamente');
        })
        .catch(error => {
          loading.hide();
          console.log(error);
          this.showSnackbarError('Error al actualizar el lenguaje');
        })
      }
    },
    addLang() {
      if (this.$refs.formlang.validate()) {
        let loading = this.$loading.show();
        if (this.unsubscribeLangs) {
          this.unsubscribeLangs();
        }
        this.ref.collection('homeLandingsTitleLanguages').doc(this.newLang.id).set({
          description:'',
          language:this.newLang.name,
          title:''
        }).then(() => {
          this.getLangs();
          this.selectedLanguageId = this.newLang.id;
          loading.hide();
          this.showSnackbarSuccess('El lenguaje se agrego correctamente, ingrese la informacion correspendiente');
          this.dialogAddLang.show = false;
        });
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    getLangs() {
      this.unsubscribeLangs = this.ref.collection('homeLandingsTitleLanguages').withConverter(DataConverter).onSnapshot(docs => {
        this.registerLanguages = []
        var index, i = 0;
        docs.forEach(doc => {
          this.registerLanguages.push(doc.data());
          if (doc.data().id == this.selectedLanguageId) {
            index = i;
          }
          i++;
        })
        this.language = this.registerLanguages[index];
        this.configLanguage = this.registerLanguages[index];
        console.log('this.registerLanguages[index]', this.registerLanguages);
        
        this.setAvailableLanguages();
      })
    },
    changeLang() {
      this.selectedLanguageId = this.language.id;
      this.configLanguage = this.language;
    },
    setAvailableLanguages() {
        this.availableLanguages = [];
        if (this.systemLanguages !== null) {
          if (this.registerLanguages !== null) {
            
            for(var i in this.systemLanguages) {
              let langId = this.systemLanguages[i].id;
              
              let exist = this.registerLanguages.find(item => item.id == langId);
              if (!exist) {
                this.availableLanguages.push(this.systemLanguages[i]);
              }
            }
          } else {
            this.availableLanguages = this.systemLanguages;
          }
        }
      },
  },
  mounted() {
    this.languageModel.getAll().onSnapshot(snap => {
      this.systemLanguages = [];
      snap.forEach( doc => {
        this.systemLanguages.push(doc.data());
      })
      this.setAvailableLanguages();
    })
    this.configsModel.getActive().get().then((snap) => {
      this.config = {}
      snap.forEach((doc) => {
        this.config = doc.data()
        this.ref = doc.ref;
      })
      if (this.unsubscribeLangs) {
        this.unsubscribeLangs();
      }
      this.getLangs();
    });
    /*this.categoriesModel.findById(this.$route.params.id).onSnapshot(snapDoc => {
      if (snapDoc.exists) {
        this.ref = snapDoc.ref;
        this.categoryExtraLanguage = {
          name:snapDoc.data().name,
          description:snapDoc.data().description,
        }
        if (this.unsubscribeLangs) {
          this.unsubscribeLangs();
        }
        this.getLangs();
      }
    })*/
  },
}
</script>

<style>

</style>